import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
var generateThumbsByType = function generateThumbsByType(list, type) {
  var count = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return list.map(function (link, id) {
    return {
      id: id + count,
      link: link,
      type: type
    };
  });
};
var getThumbsFormatted = function getThumbsFormatted() {
  var videos = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var podcasts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var images = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var thumbsPerColumn = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 5;
  if (videos.length === 0 && podcasts.length === 0 && images.length === 0) {
    return [];
  }
  var thumbs = [].concat(_toConsumableArray(generateThumbsByType(videos, 'video')), _toConsumableArray(generateThumbsByType(podcasts, 'podcast', videos.length)), _toConsumableArray(generateThumbsByType(images, 'image', videos.length + podcasts.length)));
  var count = 0;
  return thumbs.reduce(function (acc, cur, index) {
    var currentAccumulator = acc;
    var currentList = acc[count];
    if (index / thumbsPerColumn === count + 1 && index !== 0) {
      count += 1;
      return [].concat(_toConsumableArray(currentAccumulator), [[cur]]);
    }
    currentList.push(cur);
    currentAccumulator[count] = currentList;
    return currentAccumulator;
  }, [[]]);
};
export default getThumbsFormatted;