import _extends from "@babel/runtime/helpers/esm/extends";
import { PRICE_FILTER } from '../constants';
import parsePriceFilter from '../parsePriceFilter';
import adaptPriceFilter from '../adaptPriceFilter';
import { buildFilterUrl, isSelectedPrice } from './utils';
var buildPriceFilter = function buildPriceFilter(_ref) {
  var _structure$route;
  var filters = _ref.filters,
    structure = _ref.structure,
    query = _ref.query;
  var priceFilters = filters.find(function (filter) {
    return (filter == null ? void 0 : filter.type) === PRICE_FILTER;
  });
  if (!priceFilters) return null;
  var priceSelected = isSelectedPrice(structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.filters);
  var max = priceFilters.max,
    min = priceFilters.min,
    selectedMax = priceFilters.selectedMax,
    selectedMin = priceFilters.selectedMin,
    slug = priceFilters.slug;
  return _extends({}, priceFilters, {
    buildHref: function buildHref(maxValue, minValue) {
      return buildFilterUrl({
        max: adaptPriceFilter(maxValue),
        min: adaptPriceFilter(minValue),
        query: query,
        removeSelected: false,
        selected: priceSelected,
        structure: structure,
        type: slug,
        unique: true
      });
    },
    max: parsePriceFilter(max),
    min: parsePriceFilter(min),
    selected: priceSelected,
    selectedMax: selectedMax ? parsePriceFilter(selectedMax) : parsePriceFilter(max),
    selectedMin: selectedMin ? parsePriceFilter(selectedMin) : parsePriceFilter(min)
  });
};
export default buildPriceFilter;