import slugify from 'slugify';
slugify.extend({
  '.': '-'
});
slugify.extend({
  '/': '-'
});
slugify.extend({
  '\\': '-'
});
slugify.extend({
  '%': ''
});
slugify.extend({
  '&': '-'
});
export default (function () {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var lower = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return slugify(data, {
    lower: lower,
    remove: /[^A-Za-z0-9_\-\s]/g,
    replacement: '-'
  }).replace(/-+/g, '-');
});