import MagaluService from '@magalu/webzord';
var useFirebasePush = function useFirebasePush(params) {
  var logger = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : console;
  var _ref = params || {},
    event = _ref.event;
  if (event) {
    MagaluService.request({
      callback: function callback(result) {
        logger.debug('MagaluService Callback', result);
        logger.debug('payload', {
          event: event,
          params: params
        });
      },
      callbackId: "Firebase - Event: ".concat(event, " - ").concat(new Date().getTime()),
      serviceArgs: {
        eventName: event,
        eventParams: params
      },
      serviceName: 'analytics.trackEvent'
    });
  }
};
export default useFirebasePush;