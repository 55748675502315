var DAY_IN_MILLISECONDS = 86400000;
var intlRelativeToday = new Intl.RelativeTimeFormat('pt-BR', {
  numeric: 'always'
});
var intlRelativeYesterday = new Intl.RelativeTimeFormat('pt-BR', {
  numeric: 'auto'
});
var intlTimeFormat = new Intl.DateTimeFormat('pt-BR', {
  timeStyle: 'short'
});
var intlDateFormat = new Intl.DateTimeFormat('pt-BR', {
  dateStyle: 'short'
});
var getDiffDays = function getDiffDays(isoDate) {
  var now = new Date();
  var date = new Date(isoDate);
  var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  var firstDateOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  var diffInMillis = firstDateOfDay.getTime() - today.getTime();
  return Math.round(diffInMillis / DAY_IN_MILLISECONDS);
};
var getDateDistance = function getDateDistance(isoDate) {
  var diff = getDiffDays(isoDate);
  if (diff === -1) {
    var label = intlRelativeYesterday.format(diff, 'day');
    return label.charAt(0).toUpperCase() + label.slice(1);
  }
  if (diff > -7 && diff < -1) {
    return intlRelativeToday.format(diff, 'day');
  }
  var date = new Date(isoDate);
  if (diff === 0) {
    return intlTimeFormat.format(date);
  }
  return intlDateFormat.format(date);
};
export default getDateDistance;