import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["deeplink"];
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import normalizeSlashes from './normalizeSlashes';
var getVariationFromPath = function getVariationFromPath(path) {
  var data = path == null ? void 0 : path.split('/').filter(function (text) {
    return text;
  });
  if (data.length > 0) {
    var _data$, _data$2, _data$3;
    return omitBy({
      categoryId: ((_data$ = data[1]) == null ? void 0 : _data$.replace('/', '')) || null,
      productId: ((_data$2 = data[0]) == null ? void 0 : _data$2.replace('/', '')) || null,
      subCategoryId: ((_data$3 = data[2]) == null ? void 0 : _data$3.replace('/', '')) || null
    }, isNil);
  }
  return null;
};
var parseReviewsPageQuery = function parseReviewsPageQuery() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var routeId = arguments.length > 1 ? arguments[1] : undefined;
  var deeplink = _ref.deeplink,
    params = _objectWithoutProperties(_ref, _excluded);
  var pages = ['review-list', 'product-review'];
  if (pages.includes(routeId) && deeplink) {
    var data = getVariationFromPath(normalizeSlashes(deeplink));
    return data ? _extends({}, params, data) : _extends({}, params, {
      deeplink: deeplink
    });
  }
  return _extends({}, params, {
    deeplink: deeplink
  });
};
export default parseReviewsPageQuery;