import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { PRICE_FILTER, REVIEW_FILTER, CATEGORY_FILTER } from '../constants';
import buildReviewFilter from './buildReviewFilter';
import buildAttributeFilters from './buildAttributeFilters';
import buildCategoryFilter from './buildCategoryFilter';
import buildCustomCategoryFilter from './buildCustomCategoryFilter';
import buildPriceFilter from './buildPriceFilter';
export var setFilterPosition = function setFilterPosition() {
  var _filters$categoryFilt, _filters$categoryFilt2, _filters$reviewFilter, _filters$reviewFilter2, _filters$attributeFil;
  var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var hasCategoryFilters = Boolean(filters == null ? void 0 : (_filters$categoryFilt = filters.categoryFilters) == null ? void 0 : (_filters$categoryFilt2 = _filters$categoryFilt.values) == null ? void 0 : _filters$categoryFilt2.length);
  if (hasCategoryFilters) {
    filters.categoryFilters.position = 1;
    filters.categoryFilters.values.forEach(function (value) {
      value.filterPosition = 1;
    });
  }
  var hasReviewFilters = Boolean(filters == null ? void 0 : (_filters$reviewFilter = filters.reviewFilters) == null ? void 0 : (_filters$reviewFilter2 = _filters$reviewFilter.values) == null ? void 0 : _filters$reviewFilter2.length);
  if (hasReviewFilters) {
    var reviewPosition = hasCategoryFilters ? 2 : 1;
    filters.reviewFilters.position = reviewPosition;
    filters.reviewFilters.values.forEach(function (value) {
      value.filterPosition = reviewPosition;
    });
  }
  var attributeFiltersStartAt = 1 + Number(hasCategoryFilters) + Number(hasReviewFilters);
  if (filters == null ? void 0 : (_filters$attributeFil = filters.attributeFilters) == null ? void 0 : _filters$attributeFil.length) {
    filters.attributeFilters.forEach(function (filter, index) {
      var attributePosition = index + attributeFiltersStartAt;
      filter.position = attributePosition;
      filter.values.forEach(function (value) {
        value.filterPosition = attributePosition;
      });
    });
  }
  return filters;
};
var defaultRemoveFilters = [PRICE_FILTER, REVIEW_FILTER, CATEGORY_FILTER];
var filtersParser = function filtersParser() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$data = _ref.data,
    data = _ref$data === void 0 ? {} : _ref$data,
    _ref$filters = _ref.filters,
    filters = _ref$filters === void 0 ? [] : _ref$filters,
    _ref$customCategoryFi = _ref.customCategoryFilter,
    customCategoryFilter = _ref$customCategoryFi === void 0 ? false : _ref$customCategoryFi,
    _ref$removeFilters = _ref.removeFilters,
    removeFilters = _ref$removeFilters === void 0 ? [] : _ref$removeFilters,
    _ref$structure = _ref.structure,
    structure = _ref$structure === void 0 ? {} : _ref$structure,
    _ref$query = _ref.query,
    query = _ref$query === void 0 ? {} : _ref$query,
    allCategoriesLabel = _ref.allCategoriesLabel;
  return setFilterPosition({
    attributeFilters: buildAttributeFilters({
      customCategoryFilter: customCategoryFilter,
      filters: filters,
      query: query,
      removeFilters: [].concat(defaultRemoveFilters, _toConsumableArray(removeFilters)),
      structure: structure
    }),
    categoryFilters: customCategoryFilter ? buildCustomCategoryFilter(data, structure) : buildCategoryFilter({
      allCategoriesLabel: allCategoriesLabel,
      filters: filters,
      query: query,
      structure: structure
    }),
    priceFilters: buildPriceFilter({
      filters: filters,
      query: query,
      structure: structure
    }),
    reviewFilters: buildReviewFilter({
      customCategoryFilter: customCategoryFilter,
      filters: filters,
      query: query,
      structure: structure
    })
  });
};
export default filtersParser;