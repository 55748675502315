import routePush from './routePush';
var servicesRedirect = function servicesRedirect() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$type = _ref.type,
    type = _ref$type === void 0 ? 'default' : _ref$type,
    cartUrl = _ref.cartUrl;
  return routePush({
    path: "".concat(cartUrl == null ? void 0 : cartUrl.guarantee, "?typeButton=").concat(type),
    spa: true
  });
};
export default servicesRedirect;