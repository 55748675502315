var parseImportTax = function parseImportTax() {
  var _importTaxInfo$produc;
  var taxes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var importTaxId = arguments.length > 1 ? arguments[1] : undefined;
  var productId = arguments.length > 2 ? arguments[2] : undefined;
  if (!(taxes == null ? void 0 : taxes.length)) return {};
  var importTaxInfo = taxes.find(function (item) {
    return item.id === importTaxId;
  });
  var productImportTax = importTaxInfo == null ? void 0 : (_importTaxInfo$produc = importTaxInfo.products) == null ? void 0 : _importTaxInfo$produc.find(function (product) {
    return product.code === productId;
  });
  if (!(productImportTax == null ? void 0 : productImportTax.productTaxes)) return {};
  var _productImportTax$pro = productImportTax == null ? void 0 : productImportTax.productTaxes,
    realValue = _productImportTax$pro.realValue;
  return {
    icms: realValue.total.icmsValue,
    importTax: realValue.total.importTaxValue
  };
};
export default parseImportTax;