import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
var objectConvertAlias = function objectConvertAlias() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var alias = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return Object.entries(data).reduce(function (converted, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    return _extends({}, converted, _defineProperty({}, alias[key] || key, value));
  }, {});
};
export default objectConvertAlias;