import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
var addSellerParam = function addSellerParam(url, sellerId) {
  if (!sellerId) return url;
  var _url$split = url.split('?'),
    _url$split2 = _slicedToArray(_url$split, 2),
    baseUrl = _url$split2[0],
    _url$split2$ = _url$split2[1],
    params = _url$split2$ === void 0 ? '' : _url$split2$;
  var query = [].concat(_toConsumableArray(params.split('&')), ["seller_id=".concat(sellerId)]).filter(Boolean).join('&');
  return "".concat(baseUrl, "?").concat(query);
};
export default addSellerParam;