var parseCanonical = function parseCanonical(url) {
  if (!url) return undefined;
  try {
    var re = /^(http|https)/;
    if (re.test(url)) {
      var _URL = new URL(url),
        pathname = _URL.pathname;
      return pathname;
    }
    return url.startsWith('/') ? url : "/".concat(url);
  } catch (_unused) {
    return url;
  }
};
export default parseCanonical;