import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import isEmpty from 'lodash/isEmpty';
import Router from 'next/router';
var buildArgs = function buildArgs(as, options) {
  if (!isEmpty(options)) return [as, options];
  if (!isEmpty(as) && isEmpty(options)) return [as];
  return [];
};
var routeReplace = function routeReplace(_ref) {
  var path = _ref.path,
    as = _ref.as,
    options = _ref.options;
  Router.replace.apply(Router, [path].concat(_toConsumableArray(buildArgs(as, options))));
};
export default routeReplace;