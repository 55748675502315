import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import isEmpty from 'lodash/isEmpty';
import Router from 'next/router';
var nativePush = function nativePush(path) {
  window.location.href = path.startsWith('/') || path.startsWith('http') ? path : "/".concat(path);
};
var buildArgs = function buildArgs(as, options) {
  if (!isEmpty(options)) return [as, options];
  if (!isEmpty(as) && isEmpty(options)) return [as];
  return [];
};
var routePush = function routePush(_ref) {
  var path = _ref.path,
    spa = _ref.spa,
    refresh = _ref.refresh,
    _ref$as = _ref.as,
    as = _ref$as === void 0 ? undefined : _ref$as,
    _ref$options = _ref.options,
    options = _ref$options === void 0 ? {} : _ref$options;
  var targetPath = path || "".concat(window.location.pathname).concat(window.location.search);

  try {
    Object.defineProperty(document, 'referrer', {
      value: '',
      writable: true
    });
  } catch (_unused) {
    console.error('Unable to clean referrer');
  }
  if (spa || refresh) {
    try {
      Router.push.apply(Router, [targetPath].concat(_toConsumableArray(buildArgs(as, options))));
    } catch (e) {
      nativePush(targetPath);
    }
  } else {
    nativePush(targetPath);
  }
};
export default routePush;