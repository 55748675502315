import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
var composite = function composite() {
  for (var _len = arguments.length, plugins = new Array(_len), _key = 0; _key < _len; _key++) {
    plugins[_key] = arguments[_key];
  }
  return function (_ref) {
    var currentData = _ref.data,
      source = _ref.source;
    return plugins.reduce(function (data, plugin) {
      if (Array.isArray(plugin)) {
        var _plugin = _slicedToArray(plugin, 2),
          cb = _plugin[0],
          opt = _plugin[1];
        return cb(_extends({
          data: data,
          source: source
        }, opt));
      }
      return plugin({
        data: data,
        source: source
      });
    }, currentData);
  };
};
export default composite;