import { Base64 } from 'js-base64';
var isZipcodeInvalid = function isZipcodeInvalid(location) {
  try {
    if ((location == null ? void 0 : location.zipCodeChecksum) && location.zipCode) {
      return location.zipCode !== Base64.decode(location == null ? void 0 : location.zipCodeChecksum).toString('utf-8').split(':')[0];
    }
  } catch (error) {
    return true;
  }
  return false;
};
export default isZipcodeInvalid;