import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { FILTERS_SEPARATOR, FILTER_SLUG_SEPARATOR, FILTER_TYPE_SLUG_SEPARATOR } from './constants';
import mergeFiltersByType from './mergeFiltersByType';
var groupTypesValues = function groupTypesValues(_ref) {
  var _ref2 = _slicedToArray(_ref, 2),
    type = _ref2[0],
    values = _ref2[1];
  return {
    type: type,
    values: values
  };
};
var filterArrayToStr = function filterArrayToStr(filterArray) {
  if (!Array.isArray(filterArray) || !filterArray.length) {
    return typeof filterArray === 'string' || typeof filterArray === 'number' ? filterArray : '';
  }
  var mergedByType = Object.entries(filterArray.reduce(mergeFiltersByType, {})).map(groupTypesValues);
  return mergedByType.reduce(function (filters, _ref3) {
    var type = _ref3.type,
      values = _ref3.values;
    var newValue = values.join(FILTER_SLUG_SEPARATOR);
    return type && newValue ? [].concat(_toConsumableArray(filters), ["".concat(type).concat(FILTER_TYPE_SLUG_SEPARATOR).concat(newValue)]) : filters;
  }, []).join(FILTERS_SEPARATOR);
};
export default filterArrayToStr;