import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import whereAmI from './whereAmI';
var getCurrentPath = function getCurrentPath() {
  var replaceQuery = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (whereAmI.onServer) return undefined;
  var path = window.location.pathname;
  var currentQuery = window.location.search.slice(1);
  var newQuery = new URLSearchParams(currentQuery);
  Object.entries(replaceQuery).forEach(function (query) {
    newQuery.set.apply(newQuery, _toConsumableArray(query));
  });
  var newQueryString = newQuery.toString();
  return "".concat(path).concat(newQueryString ? "?".concat(newQueryString) : '');
};
export default getCurrentPath;