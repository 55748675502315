import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { PRICE_FILTER, REVIEW_FILTER } from './constants';
var getFilterValue = function getFilterValue(_ref) {
  var max = _ref.max,
    min = _ref.min,
    type = _ref.type,
    value = _ref.value;
  switch (type) {
    case REVIEW_FILTER:
      return min || value;
    case PRICE_FILTER:
      return "".concat(min, ":").concat(max);
    default:
      return value;
  }
};
var mergeFiltersByType = function mergeFiltersByType() {
  var acc = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var filter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var max = filter.max,
    min = filter.min,
    type = filter.type,
    value = filter.value;
  if (type) {
    acc[type] = [].concat(_toConsumableArray(acc[type] || []), [getFilterValue({
      max: max,
      min: min,
      type: type,
      value: value
    })]);
  }
  return acc;
};
export default mergeFiltersByType;