import MagaluService, { isSuccess } from '@magalu/webzord';
import { MAGALU_SERVICE_CLOSE_MINIAPP } from './constants';
var useCloseMiniApp = function useCloseMiniApp() {
  var logger = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : console;
  return new Promise(function (resolve, reject) {
    try {
      MagaluService.request({
        callback: function callback(result) {
          if (isSuccess(result)) {
            var _result$body;
            logger.info('[useCloseMiniApp] - success', result);
            resolve(result == null ? void 0 : (_result$body = result.body) == null ? void 0 : _result$body.close);
          } else {
            logger.error('[useCloseMiniApp] - error', result);
            resolve(false);
          }
        },
        callbackId: 'close',
        serviceName: MAGALU_SERVICE_CLOSE_MINIAPP
      });
    } catch (e) {
      logger.error('[useCloseMiniApp] - error - catch', e);
      reject(e);
    }
  });
};
export default useCloseMiniApp;