import pino from 'pino';
var Logger = function Logger(config) {
  var publicRuntimeConfig = config.publicRuntimeConfig,
    serverRuntimeConfig = config.serverRuntimeConfig;
  var level = (publicRuntimeConfig == null ? void 0 : publicRuntimeConfig.logLevel) || (serverRuntimeConfig == null ? void 0 : serverRuntimeConfig.logLevel) || 'info';
  return pino({
    browser: {
      asObject: false
    },
    level: level,
    name: 'mixer'
  });
};
export default Logger;