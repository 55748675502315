import Cookies from 'universal-cookie';
var removeCookie = function removeCookie(name, domain, path) {
  var cookies = new Cookies();
  var options = {};
  if (domain) {
    options.domain = domain;
  }
  if (path) {
    options.path = path;
  }
  return cookies.remove(name, options);
};
export default removeCookie;