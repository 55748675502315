import _extends from "@babel/runtime/helpers/esm/extends";
import { ATTRIBUTE_FILTER, MIN_OPTIONS_SHOW_FILTER_INPUT } from '../constants';
import { filterAttributesTypes, selectedAttributeFilter, buildFilterUrl } from './utils';
var buildAttributeFilters = function buildAttributeFilters(_ref) {
  var filters = _ref.filters,
    query = _ref.query,
    removeFilters = _ref.removeFilters,
    structure = _ref.structure,
    _ref$unique = _ref.unique,
    unique = _ref$unique === void 0 ? false : _ref$unique;
  var attributeFilters = filters == null ? void 0 : filters.filter(filterAttributesTypes(removeFilters));
  return (attributeFilters == null ? void 0 : attributeFilters.length) > 0 ? attributeFilters.map(function (filter) {
    var _filter$values;
    return _extends({}, filter, {
      filterSelected: selectedAttributeFilter(filter == null ? void 0 : filter.values),
      showFilterInput: (filter == null ? void 0 : (_filter$values = filter.values) == null ? void 0 : _filter$values.length) > MIN_OPTIONS_SHOW_FILTER_INPUT,
      values: filter == null ? void 0 : filter.values.map(function (item, itemIdx) {
        return _extends({}, item, {
          filterLabel: filter == null ? void 0 : filter.label,
          filterSlug: filter == null ? void 0 : filter.slug,
          href: buildFilterUrl({
            query: query,
            selected: !!(item == null ? void 0 : item.selected),
            structure: structure,
            type: filter == null ? void 0 : filter.slug,
            unique: unique || (filter == null ? void 0 : filter.type) === ATTRIBUTE_FILTER,
            value: (item == null ? void 0 : item.id) || (item == null ? void 0 : item.slug)
          }),
          position: itemIdx + 1
        });
      })
    });
  }) : [];
};
export default buildAttributeFilters;