var parseRecordsTitle = function parseRecordsTitle(_ref) {
  var records = _ref.records,
    label = _ref.label,
    _ref$limit = _ref.limit,
    limit = _ref$limit === void 0 ? 10000 : _ref$limit,
    _ref$maxedLabel = _ref.maxedLabel,
    maxedLabel = _ref$maxedLabel === void 0 ? '+ de ' : _ref$maxedLabel;
  var parsedRecords = records == null ? void 0 : records.toLocaleString('pt-BR');
  var maxedRecords = records === limit && maxedLabel.concat(parsedRecords);
  return "".concat(maxedRecords || parsedRecords, " ").concat(label);
};
export default parseRecordsTitle;