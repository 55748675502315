import MagaluService, { isSuccess } from '@magalu/webzord';
import { MAGALU_SERVICE_CUSTOMER_INFO } from './constants';
var useUserInfo = function useUserInfo(credential) {
  var logger = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : console;
  return new Promise(function (resolve, reject) {
    try {
      MagaluService.request({
        callback: function callback(result) {
          if (isSuccess(result)) {
            logger.info('[useUserInfo] - success', result);
            resolve(result == null ? void 0 : result.body);
          } else {
            logger.error('[useUserInfo] - error', result);
            reject(result);
          }
        },
        callbackId: 'userInfo',
        serviceArgs: {
          authId: credential
        },
        serviceName: MAGALU_SERVICE_CUSTOMER_INFO
      });
    } catch (e) {
      logger.error('[useUserInfo] - error - catch', e);
      reject(e);
    }
  });
};
export default useUserInfo;