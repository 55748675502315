import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
var parseFiltersMetaTags = function parseFiltersMetaTags(filters, filter) {
  var _filters$find, _filters$find$values;
  if (!filters || !Array.isArray(filters) || filters.length === 0 || !filter) return ' ';
  var _ref = (filters == null ? void 0 : (_filters$find = filters.find(function (item) {
      return item.slug === filter;
    })) == null ? void 0 : (_filters$find$values = _filters$find.values) == null ? void 0 : _filters$find$values.filter(function (item) {
      return item.selected;
    })) || [],
    _ref2 = _slicedToArray(_ref, 2),
    firstFilterSelected = _ref2[0],
    multipleFilterSelected = _ref2[1];
  return !multipleFilterSelected && firstFilterSelected ? " ".concat(firstFilterSelected.label, " ") : ' ';
};
export default parseFiltersMetaTags;