var monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

var isValidDate = function isValidDate(date) {
  return new Date(date).toString() !== 'Invalid Date' && !Number.isNaN(Number(new Date(date)));
};

var parseDateTime = function parseDateTime(date) {
  if (date && isValidDate(date)) {
    var formattedDate = new Date(date);
    var month = monthNames[formattedDate.getMonth()];
    var shortMonth = month.slice(0, 3);
    var year = formattedDate.getFullYear();
    var shortYear = Number(year.toString().slice(-2));
    var day = formattedDate.getUTCDate();
    return {
      day: day,
      getFullDate: "".concat(day, " de ").concat(month, " de ").concat(year),
      getMonthYear: "".concat(month, " ").concat(year),
      getShortDate: "".concat(day, " ").concat(shortMonth, " ").concat(shortYear),
      getShortMonthYear: "".concat(shortMonth, " ").concat(shortYear),
      month: month,
      year: year
    };
  }
  console.error("\n    The provided value does not match with a valid date time\n    please make sure you have the correct value.\n\n    How to use:\n    - parseDateTime('2022-10-24T13:52:47.000Z')\n  ");
  return {};
};
export default parseDateTime;