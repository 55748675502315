import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _typeof from "@babel/runtime/helpers/esm/typeof";
import checkExpireIn from '../checkExpireIn';
import withStorageChecker from '../withStorageChecker';
import getExpireIn from '../getExpireIn';
import { USE_STORAGE_KEY_ERROR_MSG } from '../constants/constants';
var useStorage = function useStorage(storageKey) {
  var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  if (!storageKey || typeof storageKey !== 'string') {
    console.error("".concat(USE_STORAGE_KEY_ERROR_MSG, " ").concat(_typeof(storageKey)));
  }
  var exists = function exists() {
    return Object.prototype.hasOwnProperty.call(localStorage, storageKey);
  };
  var get = function get() {
    var storage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : storageKey;
    var item = localStorage.getItem(storage);
    return item ? JSON.parse(item) : {};
  };
  var set = function set(items, ttl) {
    var storage = get();
    var isExpired = checkExpireIn(storage);
    var expireIn = isExpired || !(storage == null ? void 0 : storage.expireIn) ? getExpireIn(ttl) : storage.expireIn;
    localStorage.setItem(storageKey, JSON.stringify({
      expireIn: expireIn,
      id: id,
      items: items
    }));
  };
  var append = function append(value, ttl) {
    var _get = get(),
      _get$items = _get.items,
      items = _get$items === void 0 ? [] : _get$items;
    var isAlreadyStored = items == null ? void 0 : items.find(function (item) {
      return item.id === value.id;
    });
    if (!isAlreadyStored) {
      set([].concat(_toConsumableArray(items), [value]), ttl);
    }
  };
  var remove = function remove(value, ttl) {
    var _get2 = get(),
      _get2$items = _get2.items,
      items = _get2$items === void 0 ? [] : _get2$items;
    var updated = items == null ? void 0 : items.filter(function (item) {
      return item.id !== value;
    });
    set(updated, ttl);
  };
  return {
    append: withStorageChecker(append),
    exists: withStorageChecker(exists),
    get: withStorageChecker(get),
    remove: withStorageChecker(remove),
    set: withStorageChecker(set)
  };
};
export default useStorage;