import MagaluService, { isSuccess } from '@magalu/webzord';
import { MAGALU_SERVICE_CHECK_USER_CREDENTIALS } from './constants';
var useCheckUserCredentials = function useCheckUserCredentials() {
  var logger = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : console;
  return new Promise(function (resolve, reject) {
    try {
      MagaluService.request({
        callback: function callback(result) {
          if (isSuccess(result)) {
            var _result$body;
            logger.info('[useCheckUserCredentials] - success', result);
            resolve(result == null ? void 0 : (_result$body = result.body) == null ? void 0 : _result$body.is_logged);
          } else {
            logger.error('[useCheckUserCredentials] - error', result);
            resolve(false);
          }
        },
        callbackId: 'checkLogin',
        serviceArgs: {
          authId: ''
        },
        serviceName: MAGALU_SERVICE_CHECK_USER_CREDENTIALS
      });
    } catch (e) {
      logger.error('[useCheckUserCredentials] - error - catch', e);
      reject(e);
    }
  });
};
export default useCheckUserCredentials;