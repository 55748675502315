import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["description", "id", "label", "meta", "parent", "subcategories"];
import parsePath from './parsePath';
import slug from './slug';
var buildSubcategoryPath = function buildSubcategoryPath(item, category, page) {
  var _category$id;
  if (!item) return {};
  if (!category) return item;
  var description = item.description,
    id = item.id,
    label = item.label,
    meta = item.meta,
    parent = item.parent,
    subCategories = item.subcategories,
    otherProps = _objectWithoutProperties(item, _excluded);
  var validatePage = page === 'category' || page === 'subcategory';
  var path = (meta == null ? void 0 : meta.canonical) || "/".concat(slug(description || label), "/").concat(slug((category == null ? void 0 : category.description) || (category == null ? void 0 : category.label)), "/s/").concat(category == null ? void 0 : (_category$id = category.id) == null ? void 0 : _category$id.toLowerCase(), "/").concat(id == null ? void 0 : id.toLowerCase(), "/");
  var pathSub = (meta == null ? void 0 : meta.canonical) || "/".concat(slug(description || label), "/").concat(slug((parent == null ? void 0 : parent.description) || (parent == null ? void 0 : parent.label)), "/s/").concat(parent == null ? void 0 : parent.id.toLowerCase(), "/").concat(id == null ? void 0 : id.toLowerCase(), "/");
  return _extends({}, otherProps, {
    description: description,
    id: id,
    label: label,
    meta: meta,
    path: "".concat(!validatePage ? parsePath(path) : parsePath(pathSub)),
    subcategories: subCategories == null ? void 0 : subCategories.map(function (sub) {
      return buildSubcategoryPath(sub, category);
    })
  });
};
export default buildSubcategoryPath;