
export default (function (_ref) {
  var topTermsLabel = _ref.topTermsLabel,
    topTermsList = _ref.topTermsList,
    showTopTerms = _ref.showTopTerms;
  return {
    topTermsData: {
      label: topTermsLabel,
      list: topTermsList.map(function (item, index) {
        return {
          index: index,
          term: item
        };
      }),
      shouldDisplay: showTopTerms
    }
  };
});