import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["label", "type", "orientation"];
export var sortsFallback = [{
  label: 'Relevância',
  orientation: 'desc',
  type: 'score'
}, {
  label: 'Mais Vendidos',
  orientation: 'desc',
  type: 'soldQuantity'
}, {
  label: 'Mais Bem Avaliados',
  orientation: 'desc',
  type: 'review'
}, {
  label: 'Lançamento',
  orientation: 'desc',
  type: 'releaseDate'
}, {
  label: 'Menor Preço',
  orientation: 'asc',
  type: 'price'
}, {
  label: 'Maior Preço',
  orientation: 'desc',
  type: 'price'
}];

var gaSortFallback = function gaSortFallback() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var label = _ref.label,
    _ref$type = _ref.type,
    type = _ref$type === void 0 ? 'score' : _ref$type,
    _ref$orientation = _ref.orientation,
    orientation = _ref$orientation === void 0 ? 'desc' : _ref$orientation,
    props = _objectWithoutProperties(_ref, _excluded);
  if (label) return _extends({
    label: label,
    orientation: orientation,
    type: type
  }, props);
  return sortsFallback.find(function (sort) {
    return sort.type === type && sort.orientation === orientation;
  }) || {};
};
export default gaSortFallback;