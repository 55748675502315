import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
var _excluded = ["page", "filters"];
import getConfig from 'next/config';
import reject from 'lodash/reject';
import omit from 'lodash/omit';
import filterArrayToStr from '../filterArrayToStr';
import mergeFiltersByType from '../mergeFiltersByType';
import { FILTERS_URL_PARAM, PRICE_FILTER, REVIEW_FILTER, CATEGORY_FILTER } from '../constants';
var _ref = getConfig() || {},
  _ref$publicRuntimeCon = _ref.publicRuntimeConfig;
_ref$publicRuntimeCon = _ref$publicRuntimeCon === void 0 ? {} : _ref$publicRuntimeCon;
var SEO_CONFIG = _ref$publicRuntimeCon.SEO;
export var removeSEOQueryString = function removeSEOQueryString(query) {
  var noIndexQuery = SEO_CONFIG.noIndexQuery;
  var _noIndexQuery$split = noIndexQuery.split('='),
    _noIndexQuery$split2 = _slicedToArray(_noIndexQuery$split, 1),
    noIndexQueryKey = _noIndexQuery$split2[0];
  return omit(query, noIndexQueryKey);
};
export var getSEOQueryString = function getSEOQueryString() {
  var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var SeoConfig = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : SEO_CONFIG;
  if (!Array.isArray(filters)) return {};
  var maxFilterTypes = SeoConfig.maxFilterTypes,
    maxFilterValues = SeoConfig.maxFilterValues,
    noIndexQuery = SeoConfig.noIndexQuery;
  var _noIndexQuery$split3 = noIndexQuery.split('='),
    _noIndexQuery$split4 = _slicedToArray(_noIndexQuery$split3, 2),
    queryKey = _noIndexQuery$split4[0],
    queryValue = _noIndexQuery$split4[1];
  var seoQuery = _defineProperty({}, queryKey, queryValue);
  if (filters.length > Number(maxFilterTypes)) {
    return seoQuery;
  }
  var mergedFilters = filters.reduce(mergeFiltersByType, {});
  var tooBigFilter = Object.values(mergedFilters).find(function (values) {
    return values.length > maxFilterValues;
  });
  if (tooBigFilter) {
    return seoQuery;
  }
  return {};
};
export var urlSetFilter = function urlSetFilter(asPath, rawPath, queryString, filters) {
  var page = queryString.page,
    currentQueryFilters = queryString.filters,
    query = _objectWithoutProperties(queryString, _excluded);
  if (rawPath.includes(FILTERS_URL_PARAM)) {
    var filterParamIndex = rawPath.split('/').findIndex(function (path) {
      return path === ":".concat(FILTERS_URL_PARAM) || path === ":?".concat(FILTERS_URL_PARAM);
    });
    var urlParams = asPath.split('/');
    urlParams[filterParamIndex] = filters;
    var queryParams = new URLSearchParams(_extends({}, query)).toString();
    var newUrl = urlParams.join('/').replace('//', '/');
    return "".concat(newUrl).concat(newUrl.endsWith('/') ? '' : '/').concat(queryParams ? "?".concat(queryParams) : '');
  }
  if (filters) query.filters = filters;
  var params = new URLSearchParams(_extends({}, query)).toString();
  return "".concat(asPath).concat(params ? "?".concat(params) : '');
};
export var buildFilterUrl = function buildFilterUrl(_ref2) {
  var _structure$route, _structure$route$filt;
  var max = _ref2.max,
    min = _ref2.min,
    query = _ref2.query,
    selected = _ref2.selected,
    structure = _ref2.structure,
    type = _ref2.type,
    _ref2$unique = _ref2.unique,
    unique = _ref2$unique === void 0 ? false : _ref2$unique,
    value = _ref2.value,
    _ref2$removeSelected = _ref2.removeSelected,
    removeSelected = _ref2$removeSelected === void 0 ? true : _ref2$removeSelected;
  var currentFilters = (structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : (_structure$route$filt = _structure$route.filters) == null ? void 0 : _structure$route$filt.filter(function (filter) {
    return !(filter.origin && filter.origin === 'url') &&
    !(unique && filter.type === type);
  })) || [];
  var rejectedFilters = type === PRICE_FILTER ? reject(currentFilters, {
    max: max,
    min: min,
    type: type
  }) : reject(currentFilters, {
    type: type,
    value: value
  });
  var newFilters = removeSelected ? [].concat(_toConsumableArray(currentFilters), [{
    max: max,
    min: min,
    type: type,
    value: value
  }]) : [].concat(_toConsumableArray(rejectedFilters), [{
    max: max,
    min: min,
    type: type,
    value: value
  }]);

  var filters = selected && removeSelected ? rejectedFilters : newFilters;
  var seoQueryString = getSEOQueryString(filters);
  var filterStr = filterArrayToStr(filters);
  var asPath = structure.asPath,
    rawPath = structure.rawPath;
  return urlSetFilter(asPath, rawPath, _extends({}, removeSEOQueryString(query), seoQueryString), filterStr);
};
export var filterAttributesTypes = function filterAttributesTypes() {
  var removeFilters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [PRICE_FILTER, REVIEW_FILTER, CATEGORY_FILTER];
  return function (filter) {
    return !!(filter == null ? void 0 : filter.type) && !removeFilters.includes(filter.type);
  };
};

export var selectedAttributeFilter = function selectedAttributeFilter() {
  var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return values.filter(function (filterValue) {
    return filterValue == null ? void 0 : filterValue.selected;
  }).map(function (selected) {
    return selected == null ? void 0 : selected.label;
  }).join(', ');
};
export var parseSelectedReviews = function parseSelectedReviews(filters) {
  var review = (filters == null ? void 0 : filters.find(function (item) {
    return item.type === REVIEW_FILTER;
  })) || {};
  return Number(review == null ? void 0 : review.min) || 0;
};
export var isSelectedPrice = function isSelectedPrice(filters) {
  if (!filters) {
    return false;
  }
  return filters.findIndex(function (item) {
    return item.type === PRICE_FILTER;
  }) !== -1;
};
export var urlCleanFilters = function urlCleanFilters(asPath, rawPath, queryString) {
  var SeoConfig = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : SEO_CONFIG;
  var noIndexQuery = SeoConfig.noIndexQuery;
  var queryStringCopy = _extends({}, queryString);
  var _noIndexQuery$split5 = noIndexQuery.split('='),
    _noIndexQuery$split6 = _slicedToArray(_noIndexQuery$split5, 1),
    queryKey = _noIndexQuery$split6[0];
  delete queryStringCopy[queryKey];
  return urlSetFilter(asPath, rawPath, queryStringCopy);
};
export var urlCleanFiltersByType = function urlCleanFiltersByType(_ref3) {
  var _structure$route2, _structure$route2$fil;
  var query = _ref3.query,
    structure = _ref3.structure,
    types = _ref3.types;
  var filters = (structure == null ? void 0 : (_structure$route2 = structure.route) == null ? void 0 : (_structure$route2$fil = _structure$route2.filters) == null ? void 0 : _structure$route2$fil.filter(function (filter) {
    return !(filter.origin && filter.origin === 'url') &&
    !types.includes(filter.type);
  })) || [];
  var filterStr = filterArrayToStr(filters);
  var asPath = structure.asPath,
    rawPath = structure.rawPath;
  return urlSetFilter(asPath, rawPath, _extends({}, query), filterStr);
};
export var urlCleanSelectedFilters = function urlCleanSelectedFilters(filters, query, structure) {
  var asPath = structure.asPath,
    rawPath = structure.rawPath,
    route = structure.route;
  var routeFilters = ((route == null ? void 0 : route.filters) || []).filter(function (filter) {
    return filter.origin !== 'url';
  });
  var clearedFilters = filters.reduce(function (acc, _ref4) {
    var type = _ref4.type;
    return reject(acc, {
      type: type
    });
  }, routeFilters);
  var seoQueryString = getSEOQueryString(clearedFilters);
  var filterStr = filterArrayToStr(clearedFilters);
  return urlSetFilter(asPath, rawPath, _extends({}, removeSEOQueryString(query), seoQueryString), filterStr);
};