import { cookiesConstants, setCookie, getCookie, updateEncodedCookie } from './cookies';
var ML2_SID_C = cookiesConstants.ML2_SID_C,
  ZIPCODE = cookiesConstants.ZIPCODE,
  ZIPCODE_CITY = cookiesConstants.ZIPCODE_CITY,
  ZIPCODE_CHECKSUM = cookiesConstants.ZIPCODE_CHECKSUM,
  ZIPCODE_STATE = cookiesConstants.ZIPCODE_STATE,
  ZIPCODE_LATITUDE = cookiesConstants.ZIPCODE_LATITUDE,
  ZIPCODE_LONGITUDE = cookiesConstants.ZIPCODE_LONGITUDE;
export var errors = {
  networkError: 'Problemas ao tentar conectar! Verifique seu acesso a internet e tente novamente.',
  zipCodeNotFound: 'CEP não encontrado'
};
export var inputLocationText = function inputLocationText(e) {
  return {
    errorText: e || '',
    hasError: Boolean(e),
    labelText: 'CEP',
    placeholder: 'Digite seu cep'
  };
};
export var parseLocationError = function parseLocationError(error, location) {
  if (error && location !== null) {
    return {
      error: errors.networkError,
      type: 'integracao-api'
    };
  }
  if (location === null) {
    return {
      error: errors.zipCodeNotFound,
      type: 'cep-invalido'
    };
  }
  return {
    error: false
  };
};
export var setLocationCookies = function setLocationCookies() {
  var locationData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var zipCode = arguments.length > 1 ? arguments[1] : undefined;
  var structure = arguments.length > 2 ? arguments[2] : undefined;
  var config = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var publicRuntimeConfig = config.publicRuntimeConfig;
  var ml2SidC = getCookie(ML2_SID_C);
  var city = locationData.city,
    state = locationData.state,
    latitude = locationData.latitude,
    longitude = locationData.longitude;
  var cookieDomains = publicRuntimeConfig == null ? void 0 : publicRuntimeConfig.cookieDomain;
  var domain = cookieDomains ? cookieDomains[structure == null ? void 0 : structure.site] || (cookieDomains == null ? void 0 : cookieDomains["default"]) : null;
  if (ml2SidC) {
    var updatedMl2SidC = updateEncodedCookie({
      cookie: ml2SidC,
      index: 'zip_code',
      value: zipCode
    });
    setCookie({
      domain: domain,
      name: ML2_SID_C,
      value: updatedMl2SidC
    });
  }
  if (zipCode && city && state && latitude && longitude) {
    [{
      name: ZIPCODE,
      value: zipCode
    }, {
      name: ZIPCODE_CHECKSUM,
      value: btoa("".concat(zipCode, ":").concat(city, ":").concat(state))
    }, {
      name: ZIPCODE_CITY,
      value: encodeURIComponent(city)
    }, {
      name: ZIPCODE_STATE,
      value: state
    }, {
      name: ZIPCODE_LATITUDE,
      value: latitude
    }, {
      name: ZIPCODE_LONGITUDE,
      value: longitude
    }].forEach(function (_ref) {
      var name = _ref.name,
        value = _ref.value;
      setCookie({
        domain: domain,
        name: name,
        value: value
      });
    });
  }
};