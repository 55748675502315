export var ATTRIBUTE_FILTER = 'attribute';
export var BRAND_FILTER = 'brand';
export var CATEGORY_FILTER = 'category';
export var DELIVERY_FILTER = 'delivery_magalu';
export var FILTERS_SEPARATOR = '+';
export var FILTERS_URL_PARAM = 'filters';
export var FILTER_PRICE_SLUG_SEPARATOR = ':';
export var FILTER_SLUG_SEPARATOR = '--';
export var FILTER_TYPE_SLUG_SEPARATOR = '---';
export var MIN_OPTIONS_SHOW_FILTER_INPUT = 15;
export var PRICE_FILTER = 'price';
export var REVIEW_FILTER = 'review';
export var REVIEW_FILTER_HINT = 'e acima';
export var REVIEW_FILTER_MAX = 5;
export var SUBCATEGORY_FILTER = 'subcategory';