import MagaluService, { isSuccess } from '@magalu/webzord';
import { MAGALU_SERVICE_REQUEST_USER_CREDENTIALS } from './constants';
var useRequestUserCredential = function useRequestUserCredential() {
  var logger = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : console;
  return new Promise(function (resolve, reject) {
    try {
      MagaluService.request({
        callback: function callback(result) {
          if (isSuccess(result)) {
            var _result$body;
            logger.info('[useRequestUserCredential] - success', result);
            resolve(result == null ? void 0 : (_result$body = result.body) == null ? void 0 : _result$body.jwt);
          } else {
            logger.error('[useRequestUserCredential] - error', result);
            reject(result);
          }
        },
        callbackId: 'login',
        serviceArgs: {
          authId: ''
        },
        serviceName: MAGALU_SERVICE_REQUEST_USER_CREDENTIALS
      });
    } catch (e) {
      logger.error('[useRequestUserCredential] - error - catch', e);
      reject(e);
    }
  });
};
export default useRequestUserCredential;