import constants from './constants';
var parsePriceModality = function parsePriceModality(modalities, product) {
  try {
    var _conventionalPrice$pr, _window, _window$crypto;
    if (!(modalities == null ? void 0 : modalities.length)) {
      throw new Error(constants.NO_ONE_MODALITY_FOUND);
    }
    var conventionalPrice = modalities.find(function (modality) {
      return modality.type === constants.MODALITY_CONVENTIONAL;
    });
    if (!(conventionalPrice == null ? void 0 : (_conventionalPrice$pr = conventionalPrice.prices) == null ? void 0 : _conventionalPrice$pr.length)) {
      throw new Error(constants.NO_PRICE_MODALITY_FOUND);
    }
    var _conventionalPrice$pr2 = conventionalPrice.prices.reduce(function (acc, price) {
        if (price.currency !== constants.NATIONAL_CURRENCY) {
          acc.dolarValues = price;
        } else {
          acc.realValues = price;
        }
        return acc;
      }, {}),
      dolarValues = _conventionalPrice$pr2.dolarValues,
      realValues = _conventionalPrice$pr2.realValues;
    var priceForeign = parseFloat(product.price.originalPriceForeign);
    var _product$price = product.price,
      bestPrice = _product$price.bestPrice,
      fullPrice = _product$price.fullPrice,
      price = _product$price.price;
    var finalPrice = bestPrice || fullPrice || price;
    var priceBrl = parseFloat(finalPrice);
    var totalShippingCoastForeign = parseFloat(dolarValues.operation);
    var totalShippingCoastBrl = parseFloat(realValues.operation);
    var products = [{
      code: product.id,
      dolarValues: {
        total: priceForeign,
        totalInsurance: 0,
        totalInterestAmount: 0,
        totalShippingCoast: totalShippingCoastForeign
      },
      realValues: {
        total: priceBrl,
        totalInsurance: 0,
        totalInterestAmount: 0,
        totalShippingCoast: totalShippingCoastBrl
      }
    }];
    var totalOrderDolar = totalShippingCoastForeign + priceForeign;
    var totalOrderReal = totalShippingCoastBrl + priceBrl;
    return {
      dolarExchangeRate: dolarValues.exchangeRate,
      id: (_window = window) == null ? void 0 : (_window$crypto = _window.crypto) == null ? void 0 : _window$crypto.randomUUID == null ? void 0 : _window$crypto.randomUUID(),
      products: products,
      totalOrderDolar: totalOrderDolar,
      totalOrderReal: totalOrderReal
    };
  } catch (error) {
    return {};
  }
};
export default parsePriceModality;