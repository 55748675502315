import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
export var parseConventional = function parseConventional(deliveryTypes) {
  return deliveryTypes == null ? void 0 : deliveryTypes.find(function (_ref) {
    var type = _ref.type;
    return type === 'conventional';
  });
};
export var parseDeliveryTypes = function parseDeliveryTypes(modalities) {
  return modalities == null ? void 0 : modalities.map(function (_ref2) {
    var price = _ref2.cost.customer,
      id = _ref2.id,
      _ref2$shippingTime = _ref2.shippingTime,
      description = _ref2$shippingTime.description,
      disclaimers = _ref2$shippingTime.disclaimers,
      value = _ref2$shippingTime.value,
      type = _ref2.type;
    return {
      complement: disclaimers[0].message,
      description: description,
      id: id,
      price: price == null ? void 0 : price.toString(),
      time: value == null ? void 0 : value.max,
      type: type
    };
  });
};
var parseHubShipping = function parseHubShipping() {
  var deliveries = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  if (Array.isArray(deliveries) && !deliveries.length) return [];
  var _deliveries = _slicedToArray(deliveries, 1),
    delivery = _deliveries[0];
  var deliveryTypes = parseDeliveryTypes(delivery == null ? void 0 : delivery.modalities);
  var conventionalType = parseConventional(deliveryTypes);
  return [{
    packages: [_extends({}, conventionalType, {
      deliveryTypes: deliveryTypes
    })]
  }];
};
export default parseHubShipping;