import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/esm/extends";
var _excluded = ["values"];
import { CATEGORY_FILTER, SUBCATEGORY_FILTER } from '../constants';
import buildAttributeFilters from './buildAttributeFilters';
import { urlCleanFiltersByType } from './utils';
var getSlugByIndex = function getSlugByIndex(index) {
  return index ? SUBCATEGORY_FILTER : CATEGORY_FILTER;
};
var getCleanTypesByIndex = function getCleanTypesByIndex(index) {
  return index ? [SUBCATEGORY_FILTER] : [CATEGORY_FILTER, SUBCATEGORY_FILTER];
};
var addInset = function addInset(index) {
  return function (data) {
    return _extends({}, data, {
      inset: index ? 2 : 0
    });
  };
};
var buildCategoryFilter = function buildCategoryFilter() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$allCategoriesLab = _ref.allCategoriesLabel,
    allCategoriesLabel = _ref$allCategoriesLab === void 0 ? 'Todos os departamentos' : _ref$allCategoriesLab,
    _ref$filters = _ref.filters,
    filters = _ref$filters === void 0 ? [] : _ref$filters,
    _ref$query = _ref.query,
    query = _ref$query === void 0 ? {} : _ref$query,
    structure = _ref.structure;
  var categoryFilter = filters.find(function (filter) {
    return (filter == null ? void 0 : filter.type) === CATEGORY_FILTER;
  });
  if (!categoryFilter) return null;
  var values = categoryFilter.values,
    filterInfo = _objectWithoutProperties(categoryFilter, _excluded);
  var parsedFilter = _extends({}, filterInfo, {
    componentType: 'hierarchical',
    values: []
  });
  var addPosition = function addPosition(filter) {
    return _extends({}, filter, {
      values: filter.values.map(function (item, index) {
        return _extends({}, item, {
          position: index + 1
        });
      })
    });
  };
  var parseValues = function parseValues(filterValues) {
    var _filterValues$, _filterValues$2, _filterValues$3, _filterValues$4;
    var parentValues = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var index = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    if (parentValues.length && !(filterValues == null ? void 0 : (_filterValues$ = filterValues[0]) == null ? void 0 : _filterValues$.selected)) parsedFilter.values.push(_extends({}, parentValues[0], {
      inset: 1
    }));

    if (!(filterValues == null ? void 0 : filterValues.length)) return addPosition(parsedFilter);

    if (!(filterValues == null ? void 0 : (_filterValues$2 = filterValues[0]) == null ? void 0 : _filterValues$2.selected)) {
      var currentLevel = buildAttributeFilters({
        filters: [_extends({}, filterInfo, {
          slug: getSlugByIndex(index),
          values: filterValues
        })],
        query: query,
        removeFilters: [],
        structure: structure,
        unique: true
      })[0];
      parsedFilter.values = parsedFilter.values.concat(currentLevel.values.map(addInset(index)));
      return addPosition(parsedFilter);
    }

    parsedFilter.values.push(_extends({}, (parentValues == null ? void 0 : parentValues[0]) || {
      label: allCategoriesLabel
    }, {
      count: null,
      href: urlCleanFiltersByType({
        query: _extends({}, query, {
          page: 1
        }),
        structure: structure,
        types: getCleanTypesByIndex(index)
      }),
      icon: 'ArrowBack',
      inset: 0
    }));

    if ((filterValues == null ? void 0 : (_filterValues$3 = filterValues[0]) == null ? void 0 : _filterValues$3.parent) && parentValues[0].id !== (filterValues == null ? void 0 : (_filterValues$4 = filterValues[0]) == null ? void 0 : _filterValues$4.parent.id)) {
      var _filterValues$5;
      var parent = buildAttributeFilters({
        filters: [_extends({}, filterInfo, {
          slug: SUBCATEGORY_FILTER,
          values: [filterValues == null ? void 0 : (_filterValues$5 = filterValues[0]) == null ? void 0 : _filterValues$5.parent]
        })],
        query: query,
        removeFilters: [],
        structure: structure,
        unique: true
      })[0];
      parsedFilter.values = parsedFilter.values.concat([_extends({}, parent.values[0], {
        count: null,
        icon: 'ArrowBack',
        inset: 0
      })]);
    }
    return parseValues(filterValues[0].values, filterValues, index + 1);
  };
  return parseValues(values);
};
export default buildCategoryFilter;