import _extends from "@babel/runtime/helpers/esm/extends";

var WPM = 275;
var countWords = function countWords(text) {
  return text ? text.trim().split(/\s+/).length : 0;
};
var readingTimeCount = function readingTimeCount(count) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _options$wpm = options.wpm,
    wpm = _options$wpm === void 0 ? WPM : _options$wpm;
  var minutes = count / wpm;
  return {
    minutes: Math.ceil(parseFloat(minutes.toFixed(2))),
    wpm: wpm
  };
};

var readingTimeEstimator = function readingTimeEstimator(text) {
  var count = countWords(text);
  return _extends({
    count: count
  }, readingTimeCount(count));
};
export default readingTimeEstimator;