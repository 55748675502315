var parseStringToBoolean = function parseStringToBoolean(str) {
  var fallback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (typeof str === 'boolean') return str;
  if (!str || typeof str !== 'string') return fallback;
  try {
    var _boolean = JSON.parse(str);
    if (typeof _boolean === 'number') return Boolean(_boolean);
    return typeof _boolean === 'boolean' ? _boolean : fallback;
  } catch (e) {
    return fallback;
  }
};
export default parseStringToBoolean;