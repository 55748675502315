var cartRedirect = function cartRedirect() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$type = _ref.type,
    type = _ref$type === void 0 ? 'default' : _ref$type,
    cartUrl = _ref.cartUrl,
    next = _ref.next;
  var url = cartUrl[type] || cartUrl["default"];
  var nextParam = next ? "/?next=".concat(next, "&origin=magazineluiza") : '';
  window.location.replace("".concat(url).concat(nextParam));
};
export default cartRedirect;