import _extends from "@babel/runtime/helpers/esm/extends";
import buildArrayByRange from '../buildArrayByRange';
import { REVIEW_FILTER, REVIEW_FILTER_HINT } from '../constants';
import { buildFilterUrl, parseSelectedReviews } from './utils';

var buildReviewFilter = function buildReviewFilter(_ref) {
  var _structure$route;
  var filters = _ref.filters,
    structure = _ref.structure,
    query = _ref.query;
  var reviewFilters = filters.find(function (filter) {
    return (filter == null ? void 0 : filter.type) === REVIEW_FILTER;
  });
  if (!reviewFilters) return null;
  var reviewsSelected = parseSelectedReviews(structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.filters);
  var reviews = buildArrayByRange((reviewFilters == null ? void 0 : reviewFilters.min) || 1, reviewFilters == null ? void 0 : reviewFilters.max).map(function (value, idx) {
    return {
      filterLabel: reviewFilters == null ? void 0 : reviewFilters.label,
      filterSlug: reviewFilters == null ? void 0 : reviewFilters.slug,
      hint: value !== (reviewFilters == null ? void 0 : reviewFilters.max) ? REVIEW_FILTER_HINT : undefined,
      href: buildFilterUrl({
        query: query,
        selected: reviewsSelected === value,
        structure: structure,
        type: reviewFilters == null ? void 0 : reviewFilters.slug,
        unique: true,
        value: value
      }),
      label: "".concat(value),
      max: reviewFilters == null ? void 0 : reviewFilters.max,
      position: idx + 1,
      selected: reviewsSelected === value,
      slug: value,
      value: value
    };
  }).reverse();
  return _extends({}, reviewFilters, {
    filterSelected: (reviews == null ? void 0 : reviews.find(function (value) {
      return value == null ? void 0 : value.selected;
    })) || {},
    values: reviews
  });
};
export default buildReviewFilter;