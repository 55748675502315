var normalize = function normalize() {
  var newString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var string = newString;
  if (typeof string !== 'string') return null;
  var mapReplacement = {
    A: /[\xC0-\xC6]/g,
    a: /[\xE0-\xE6]/g,
    C: /\xC7/g,
    c: /\xE7/g,
    E: /[\xC8-\xCB]/g,
    e: /[\xE8-\xEB]/g,
    I: /[\xCC-\xCF]/g,
    i: /[\xEC-\xEF]/g,
    N: /\xD1/g,
    n: /\xF1/g,
    O: /[\xD2-\xD6]/g,
    o: /[\xF2-\xF6]/g,
    U: /[\xD9-\xDC]/g,
    u: /[\xF9-\xFC]/g
  };
  Object.keys(mapReplacement).forEach(function (character) {
    var regexTerm = mapReplacement[character];
    string = string.replace(regexTerm, character).toLowerCase();
  });
  return string;
};
export default normalize;