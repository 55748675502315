export var getFactsheetValueFromItem = function getFactsheetValueFromItem(data) {
  if (!data) return null;
  if (typeof data === 'string') return data;
  if (Array.isArray(data)) return getFactsheetValueFromItem(data[0]);
  return getFactsheetValueFromItem((data == null ? void 0 : data.elements) || (data == null ? void 0 : data.value));
};
export var getFactsheetValueFromSlug = function getFactsheetValueFromSlug(factsheet, slug) {
  return getFactsheetValueFromItem(factsheet == null ? void 0 : factsheet.find(function (i) {
    return i.slug === slug;
  }));
};