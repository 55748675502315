import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["deeplink"];
import normalizeSlashes from './normalizeSlashes';
var getVariationFromPath = function getVariationFromPath(path) {
  var _path$split;
  var variationId = path == null ? void 0 : (_path$split = path.split('/q/a/')) == null ? void 0 : _path$split[1];
  if (variationId) return variationId.replace('/', '');
  return null;
};
var parseQuestionsPageQuery = function parseQuestionsPageQuery() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var deeplink = _ref.deeplink,
    params = _objectWithoutProperties(_ref, _excluded);
  var questionVariationId = null;
  if (deeplink) {
    questionVariationId = getVariationFromPath(normalizeSlashes(deeplink));
  }
  if (Object.keys(params).length > 0) {
    var pathMiniApp = normalizeSlashes(Object.keys(params)[0]);
    questionVariationId = getVariationFromPath(pathMiniApp);
  }
  if (questionVariationId) return _extends({}, params, {
    questionVariationId: questionVariationId
  });
  return _extends({}, params, {
    deeplink: deeplink
  });
};
export default parseQuestionsPageQuery;