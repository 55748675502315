import React from 'react';
var parseTextHighlight = function parseTextHighlight(dataStr) {
  var testid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'highlight';
  var data = dataStr.trim();
  var oddWithSpan = data.startsWith('"');
  return data.split('"').filter(Boolean).map(function (part, idx) {
    var odd = !(idx % 2);
    return oddWithSpan === odd ? React.createElement("span", {
      key: part,
      "data-testid": testid
    }, part) : part;
  });
};
export default parseTextHighlight;