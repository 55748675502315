var scrollToRef = function scrollToRef() {
  var ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var yOffset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  setTimeout(function () {
    var _el$getBoundingClient;
    var el = ref == null ? void 0 : ref.current;
    var y = (el == null ? void 0 : (_el$getBoundingClient = el.getBoundingClientRect()) == null ? void 0 : _el$getBoundingClient.top) + window.pageYOffset + yOffset;
    window.scrollTo({
      behavior: 'smooth',
      top: y
    });
  }, 100);
};
export default scrollToRef;