import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _extends from "@babel/runtime/helpers/esm/extends";
import HTMLParser from 'node-html-parser';
import { FORMAT_TEXT_TAGS, GENERAL_TAGS } from './constants';

function filterByIntersections(arrayA, arrayB) {
  return arrayA.filter(function (value) {
    return arrayB.includes(value);
  });
}

var sanitizeHtml = function sanitizeHtml(htmlString, options) {
  var settings = _extends({}, sanitizeHtml.settings, options);

  if (settings.rawText) {
    settings.deniedTags = [].concat(_toConsumableArray(FORMAT_TEXT_TAGS), _toConsumableArray(GENERAL_TAGS));
  } else {
    settings.deniedTags = _toConsumableArray(new Set([].concat(_toConsumableArray(sanitizeHtml.settings.deniedTags), _toConsumableArray(settings.deniedTags))));
  }
  function setup() {
    try {
      return HTMLParser.parse(htmlString);
    } catch (error) {
      console.error("\n        An error ocurred while sanitizing an html, please make\n        sure you provide a valid html string.\n\n        How to use:\n        - sanitizeHtml(htmlString, { ...options })\n\n        Error message:\n        - ".concat(error, "\n      "));
      return null;
    }
  }

  function removeFormatTextTags(html) {
    var tags = html.querySelectorAll(filterByIntersections(settings.deniedTags, FORMAT_TEXT_TAGS).join(', '));
    tags.forEach(function (tag) {
      if (settings.preserveContent) {
        tag.replaceWith(tag.textContent.concat(' '));
      } else {
        tag.remove();
      }
    });
  }

  function removeGeneralTags(html) {
    var tags = html.querySelectorAll(filterByIntersections(settings.deniedTags, GENERAL_TAGS).join(', '));
    tags.forEach(function (tag) {
      tag.remove();
    });
  }
  var html = setup();
  if (html) {
    removeFormatTextTags(html);
    removeGeneralTags(html);
    return html.innerHTML.trim();
  }
  return html;
};
sanitizeHtml.settings = {
  allowedTags: [],
  deniedTags: ['meta', 'link', 'script', 'style'],
  preserveContent: true,
  rawText: false
};
export default sanitizeHtml;