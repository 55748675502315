import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
var hasSomeEqualProp = function hasSomeEqualProp(sourceItem, baseItem) {
  var _Object$keys, _Object$keys2;
  if (!((_Object$keys = Object.keys(sourceItem)) == null ? void 0 : _Object$keys.length) || !((_Object$keys2 = Object.keys(baseItem)) == null ? void 0 : _Object$keys2.length)) return sourceItem === baseItem;
  return Object.entries(baseItem).some(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      keyBaseItem = _ref2[0],
      valueBaseItem = _ref2[1];
    return sourceItem[keyBaseItem] === valueBaseItem;
  });
};
export default hasSomeEqualProp;