import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime/helpers/esm/extends";
import _typeof from "@babel/runtime/helpers/esm/typeof";
var onlyDefinedProps = function onlyDefinedProps(obj) {
  if (Array.isArray(obj)) return obj.length ? obj : {};
  if (!obj || _typeof(obj) !== 'object') {
    return {};
  }
  return Object.keys(obj).reduce(function (objWithOnlyDefinedProps, key) {
    if (obj[key] === undefined) return objWithOnlyDefinedProps;
    return _typeof(obj[key]) === 'object' ? _extends({}, objWithOnlyDefinedProps, _defineProperty({}, key, onlyDefinedProps(obj[key]))) : _extends({}, objWithOnlyDefinedProps, _defineProperty({}, key, obj[key]));
  }, {});
};
export default onlyDefinedProps;